import "lazysizes";
import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import {FloatLabels} from "@keodesign/float-labels";
import Masonry from "masonry-layout";
import VenoBox from "venobox/dist/venobox.min.js";

var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
window.onload = function() {    
	setupSlideMenu();
	const map_click = document.getElementById("map-click");
	if (map_click) {
		map_click.addEventListener("click", () => {
			map_click.style.display = "none";
		});
	}

	setupGalleryCarousel();
	setupGalleryCarousel3Items();
	setupSlider();
	setupGalleryAutoScroll();
	setupFAQ();
	window.kms_bannerRotate();
	setupPopupBanner();
	new FloatLabels(".js-float-wrap");
	setupMoreNews();
	setupGalleryMasonry();
};



export function setupGalleryMasonry(){

	var elem = document.querySelector(".masonry-layout");
	if(elem){
		new Masonry( elem, {
			itemSelector: ".item",
			columnWidth: ".grid-sizer",
			percentPosition: true
	
		});
		new VenoBox({
			selector: ".masonry-layout > .item",
			infinigall: true,
			bgcolor: "none",
		});
	
		var open_gallery = document.querySelector(".masonry-layout > .item.open");
		if (open_gallery){
			open_gallery.click();
		}
	}
}
export function setupFAQ(){
	const faq_cards = document.querySelectorAll(".faq-card");
	let first_flag = true;
	if(faq_cards){
		faq_cards.forEach(el => {
			let title = el.querySelector(".faq-title");
			let body = el.querySelector(".faq-body");
			let originalHeight = body.offsetHeight;
			const toggleHide = () => {
				el.classList.toggle("hide");
				if (el.classList.contains("hide")){
					body.style.maxHeight = 0 + "px";
				}
				else{
					body.style.maxHeight = originalHeight + "px";
				}
			};

			if (title){
				title.addEventListener("click", toggleHide);
			}
			if (!first_flag){
				toggleHide();
			}
			first_flag = false;
		});
	}	
}

export const getRandomInt = (max) => {
	return Math.floor(Math.random() * max);
};

export const getListOfRandomInt = (number_of_random_number, max) => {
	let rand_list = new Array();
	while(rand_list.length < number_of_random_number && rand_list.length < max){
		let random = getRandomInt(max);
		if (rand_list.includes(random)){
			continue;
		}
		rand_list.push(random);
	}
	return rand_list;
};

export function setupMoreNews(){
	const news_wrapper = document.querySelector(".projects-items");
	if (news_wrapper){
		fetch("/content/product/?format=json").then(
			response => response.json()
		).then(
			data => {
				if("content_items" in data){
					const random_items = getListOfRandomInt(3, data["content_items"].length);
					random_items.forEach(random_int => {
						const el = data["content_items"][random_int];
						let anchor = document.createElement("a");
						anchor.href = el.url;
						let card = document.createElement("div");
						card.className  = "card";

						if ("icon_url" in el && el["icon_url"]){
							let img_wrapper = document.createElement("div");
							img_wrapper.className = "img-wrapper";

							let img = document.createElement("img");
							img.src = el["icon_url"];
							img_wrapper.append(img);
							card.append(img_wrapper);
						}
						if ("title" in el && el["title"]){
							let teaser = document.createElement("p");
							teaser.innerHTML = el["title"];
							card.append(teaser);
						}
						anchor.append(card);
						news_wrapper.append(anchor);
					});
				}
			}
		);
	}
}

export function setupGalleryCarousel3Items(){
	var elms = document.getElementsByClassName("gallery-carousel-3-items");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "loop",
			drag   : true,
			snap   : true,
			arrows : false,
			autoplay: true,
			pagination : false,
			perPage: 3,
			mediaQuery : "max",
			breakpoints: {
				768: {
					perPage: 2,
				},
				576: {
					perPage: 1,
				},
			}
		}).mount();
	}
}

export function setupGalleryCarousel(){
	var elms = document.getElementsByClassName("gallery-carousel");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "loop",
			drag   : true,
			snap   : true,
			arrows : false,
			padding: "20%",
			autoplay: true,
			pagination : false,
			mediaQuery : "max",
			breakpoints: {
				768: {
					padding: "10%",
				},
				576: {
					padding: "0",
				},
			}
		}).mount();
	}
}

export function setupSlider(){
	var elms = document.getElementsByClassName("slider");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "fade",
			rewind    : true,
			pagination: false,
			arrows    : false,
			autoplay: true,
		}).mount();
	}
}

export function setupGalleryAutoScroll(){
	var elms = document.getElementsByClassName("gallery-autoscroll");
	for ( var i = 0; i < elms.length; i++ ) {
		new Splide( elms[i], {
			type   : "loop",
			drag   : "free",
			pagination: false,
			arrows    : false,
			focus  : "center",
			perPage: 3,
			autoScroll: {
				speed: 1,
				rewind: true
			},
			mediaQuery : "max",
			breakpoints: {
				768: {
					perPage: 2,
				},
				576: {
					perPage: 1,
				},
			}
		}).mount( { AutoScroll } );
	}
}

export function setupPopupBanner(){
	$(".pop-banner").each(function(index, el){
 
		$(el).magnificPopup({
			delegate:".kmsgallery-thumbnail-image",
			type: "image",
			gallery:{
				enabled:true
			},
			image: {
				markup: "<div class=\"mfp-figure\">" +
						"	<div class=\"mfp-close\"></div>" +
						"	<div class=\"mfp-img\"></div>" +
						"	<div class=\"mfp-bottom-bar\">" +
						"		<div class=\"mfp-title\"></div>" +
						"		<div class=\"mfp-counter\"></div>"+
						"	</div>"+
						"</div>", // Popup HTML markup. `.mfp-img` div will be replaced with img tag, `.mfp-close` by close button
				cursor: null, // Class that adds zoom cursor, will be added to body. Set to null to disable zoom out cursor.
				titleSrc: "title", // Attribute of the target element that contains caption for the slide.
				verticalFit: false, // Fits image in area vertically
				tError: "<a href=\"%url%\">The image</a> could not be loaded." // Error message
			},
			callbacks: {
				open: function() {
					const lets_talk = document.querySelector(".mfp-bottom-bar .lets-talk");
					if (lets_talk){
						lets_talk.addEventListener("click", () => {
							this.close();
							$([document.documentElement, document.body]).animate({
								scrollTop: $("#contact").offset().top
							}, 1000);
						});
					}
				},
			}
		});

		
		
	});
}

export function setupSlideMenu(){
	const menu_buttons = [...document.querySelectorAll("#mobile-menu, .slide-menu h3, .slide-menu .close-btn")];

	menu_buttons.forEach(el => {
		el.addEventListener("click", ()=>{
			$("body").toggleClass("slide-menu-active");
		});
	});
	const menu_items = [...document.querySelectorAll(".slide-menu li")];
	menu_items.forEach((element) => {		
		if ([...element.querySelectorAll("ul")].length > 0) {
			element.insertAdjacentHTML("beforeend","<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			
			// expander.click(function(e) {
			// 	e.stopPropagation();
			// 	$el.toggleClass("expand-active");
			// });
		}
	});
	const menu_expanders = [...document.querySelectorAll(".slide-menu .expand")];
	menu_expanders.forEach(el => {
		el.addEventListener("click", (e)=> {
			e.stopPropagation();
			el.closest("li").classList.toggle("expand-active");
		});
	});

}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener("lazybeforeunveil", function(e){
	var container = e.target;
	var bgUrl = container.dataset.bgUrl;
	if (bgUrl) {
		container.style.backgroundImage = "url('" + bgUrl + "')";
		delete container.dataset.bgUrl;
	}
});